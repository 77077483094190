import styled from 'styled-components'
const padding = `2.3rem`
const smallPadding = '1rem'
const LogosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  i {
    &:first-child {
      padding-right: ${padding};
      border-right: 0.1rem solid grey;
      @media screen and (max-width: 450px) {
        padding-right: ${smallPadding};
      }
    }
    &:last-child {
      padding-left: ${padding};
      @media screen and (max-width: 450px) {
        padding-left: ${smallPadding};
      }
    }
  }
`

export default LogosWrapper
