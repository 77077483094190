import styled from 'styled-components'
const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50rem;
  position: relative;
  width: 100%;
  margin-bottom: 13.75rem;
  height: 54rem;
  @media screen and (max-width: 650px) {
    height: 43rem;/
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 16rem;
  }
  @media screen and (max-width: 380px) {
    margin-bottom: 19rem;
  }
`
export default Wrapper