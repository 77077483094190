import React from 'react'
import Wrapper from 'Blocks/Button/Wrapper'

const Button = (props) => {
  return (
    <Wrapper
      headerButton={props.headerButton}
      onClick={props.clickHandler}
      type={props.type}
    >
      {props.label}
    </Wrapper>
  )
}

export default Button
