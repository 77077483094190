import styled from 'styled-components'
import { darken } from 'polished'
const Wrapper = styled.button`
  align-items: center;
  border: none;
  color: ${(props) => props.textColor || 'black'};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  outline: none;
  text-transform: uppercase;
  transition: ${(props) => props.theme.animation.slowTransition};
  ${(props) =>
    props.type === 'redButton' &&
    `
    background-color: ${props.theme.colors.red};
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 900;
    line-height: 0;
    padding: 1.5rem 1rem;
    &:hover {
      background-color: ${darken('0.2', props.theme.colors.red)}
    }
  `}
  ${(props) =>
    props.headerButton &&
    `
    @media screen and (max-width: 440px) {
      font-size: 1rem
    }
  `}
`
export default Wrapper
