const animation = {
  slowTransition: 'all 300ms',
  cardFlip: 'transform 800ms ease',
  modalTimer: 200
}
const breakpoints = {
  maxSmallPhone: '400px',
  maxPhone: '700px',
  maxTablet: '800px'
}
const colors = {
  blue: 'rgb(0, 72, 224)',
  darkBlue: 'rgb(8, 85, 138)',
  lightBlue: 'rgba(16,128,216, .1)',
  lightGrey: 'rgb(35, 31, 32)',
  lighterGrey: 'rgb(179, 182, 184)',
  lightestGrey: 'rgb(225, 226, 227)',
  gold: 'rgb(191, 149, 28)',
  pink: 'rgb(253, 204, 186)',
  red: 'rgb(226, 25, 41)',
  transparentBlack: 'rgba(0, 0, 0, 0.75)',
}
const fonts = {
  primary: 'museo-sans, sans-serif'
}
const shadows = {
  buttonShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.2)',
  cardShadow: '0px 4px 0px 0px rgba(0,0,0,0.1)',
}
const sizes = {
  cardBigWidth: '25.65rem',
  cardBigHeight: '23.57rem',
  cardIconBig: '9rem',
}

export const admrTheme = {
  animation,
  breakpoints,
  colors,
  fonts,
  shadows,
  sizes
}