import styled from 'styled-components'
const Wrapper = styled.header`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 1.3rem 4rem 1.3rem 4rem;
  width: 100%;
  position: fixed;
  background-color: white;
  z-index: 1;

  @media screen and (max-width: 600px) {
    padding: 1.3rem 3rem;
  }
  @media screen and (max-width: 450px) {
    padding: 1.3rem 1.2rem;
  }
`
const InnerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`
export { Wrapper, InnerWrapper }
