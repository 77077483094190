import styled from 'styled-components'
import BonusButton from 'PNG/bonus.png'

const Bonus = styled.img.attrs({
  src: BonusButton,
})`
  height: 3.023333rem;
  width: 8.75rem;
`
export default Bonus
