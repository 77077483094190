import React from 'react'
import styled from 'styled-components'
import { RedirectingMessage, NoMatchMessage } from './Messages'
import CloseButton from './CloseButton'
import ZipForm from 'Blocks/ZipForm'
const PaneContainer = styled.aside`
  align-items: center;
  background-color: white;
  box-shadow: 0px 3px 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  left: 50%;
  padding: 20px 50px;
  min-height: 180px;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  transform: perspective(1px) translateY(-50%) translateX(-50%);
  width: 557px;
  z-index: 101;
  @media screen and (max-width: 700px) {
    max-width: 80%;
    width: 100%;
  }
  @media screen and (max-width: 550px) {
    max-width: none;
  }
`


const Pane = (props) => {
  const modalMessage = {
    unChecked: '',
    matched: <RedirectingMessage
      clubcode={props.clubcode}
      state={props.state}
      url={props.url}
      zip={props.queryZip}
    />,
    unMatched: <NoMatchMessage zip={props.queryZip}/>,
  }
  return (
    <PaneContainer>
      <CloseButton onClick={props.clickClose} />
      {props.resultsMessage && modalMessage[props.resultsMessage]}
      {props.resultsMessage !== 'unMatched' &&
        <ZipForm
        isInModal
        onClickButton={props.onClickButton}
        setZip={props.setZip}
        zip={props.zip}
      />}
    </PaneContainer>
  )
}
export default Pane
