import React from 'react'
import styled from 'styled-components'

const RedZip = styled.span`
  color: ${props => props.theme.colors.red};
`

const Wrapper = styled.div`
  text-align: center;
  p {
    font-size: 1.4rem;
    margin: .7rem 0 0;
  }
  h3 {
    font-size: 1.7rem;
    margin: 0;
  }
`

const RedirectingMessage = (props) => {
  return (
    <Wrapper>
      <h3>Success</h3>
      <p>
        Redirecting you. If you are not redirected in 2 seconds,{' '}
        <a href={props.url}>click here</a>
      </p>
    </Wrapper>
  )
}

const NoMatchMessage = (props) => {
  return (
    <Wrapper>
      <h3>
        Results for "<RedZip>{props.zip}</RedZip>"
      </h3>
      <p>Sorry, but this offer is not available in your area at this time.</p>
      <p>But you can still get AAA’s legendary 24-Hr. Roadside Assistance Services year-round!</p>
      <p><a href="https://www.aaa.com" target="_blank" rel="noopener noreferrer" >Click here to find out more.</a></p>
    </Wrapper>
  )
}

export { RedirectingMessage, NoMatchMessage }
