import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Towtruck } from 'SVG/towtruck.svg'

const Wrapper = styled.figure`
  align-items: center;
  display: flex;
  height: 4.9rem;
  justify-content: center;
  margin: 0;
  width: 16.85rem;
  svg {
    height: 100%;
  }
`
const SupplementalIcon = () => {
  return (
    <Wrapper>
      <Towtruck />
    </Wrapper>
  )
}

export default SupplementalIcon
