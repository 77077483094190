import React from 'react'

import styled from 'styled-components'

import { ReactComponent as Adventurer } from 'SVG/card-icon-adventurer.svg'
import { ReactComponent as AutoBuyer } from 'SVG/card-icon-autobuyer.svg'
import { ReactComponent as BargainHunter } from 'SVG/card-icon-bargainhunter.svg'
import { ReactComponent as Cyclist } from 'SVG/card-icon-cyclist.svg'
import { ReactComponent as FinancialPlanner } from 'SVG/card-icon-financialplanner.svg'
import { ReactComponent as HungryTraveler } from 'SVG/card-icon-hungrytraveler.svg'
import { ReactComponent as OnlineShopper } from 'SVG/card-icon-onlineshopper.svg'
import { ReactComponent as Parent } from 'SVG/card-icon-parent.svg'
import { ReactComponent as SafeTeenDriver } from 'SVG/card-icon-safeteendriver.svg'

const Wrapper = styled.figure`
  max-height: ${(props) => props.theme.sizes.cardIconBig};
  max-width: ${(props) => props.theme.sizes.cardIconBig};
  height: ${(props) => props.theme.sizes.cardIconBig};
  width: ${(props) => props.theme.sizes.cardIconBig};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    height: 100%;
  }
`
const icons = {
  Adventurer: <Adventurer />,
  'Auto Buyer': <AutoBuyer />,
  'Bargain Hunter': <BargainHunter />,
  Cyclist: <Cyclist />,
  'Financial Planner': <FinancialPlanner />,
  'Hungry Traveler': <HungryTraveler />,
  'Online Shopper': <OnlineShopper />,
  Parent: <Parent />,
  'Safe Teen Driver': <SafeTeenDriver />,
}

const CardIcon = (props) => {
  return <Wrapper>{icons[props.for]}</Wrapper>
}

export default CardIcon
