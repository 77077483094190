import React from 'react'

import Button from 'Blocks/Button'
import { HeaderLogo } from 'Blocks/Image'
import LogosWrapper from './LogosWrapper'
import {Wrapper, InnerWrapper} from './Wrapper'

const Header = (props) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <LogosWrapper>
          <HeaderLogo name="fuelRewards" />
          <HeaderLogo name="aaaOrbit" />
        </LogosWrapper>
        <Button
          headerButton
          clickHandler={props.toggleModal}
          label="Enter zip for special aaa offer"
          type="redButton"
        />
      </InnerWrapper>
    </Wrapper>
  )
}

export default Header
