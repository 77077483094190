import { createGlobalStyle } from 'styled-components'
const GlobalStyle = createGlobalStyle`
  @import url("https://use.typekit.net/ott3jgj.css");
  * {
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%; 
  }

  body {
    font-family: ${(props) => props.theme.fonts.primary};
  }
`
export default GlobalStyle
