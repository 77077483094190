import React from 'react'
import styled from 'styled-components'
import Hero from 'Video/hero.mp4'
import HeroOgg from 'Video/hero.ogv'
import HeroPoster from 'JPG/heroposter.jpg'
const VideoWrapper = styled.video`
  height: auto;
  left: 0;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: auto;
  z-index: -1;
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    object-fit: cover;
    position: static
  }
`

const Video = (props) => {
  return (
    <VideoWrapper autoPlay muted poster={HeroPoster}>
      <source src={Hero} type="video/mp4" />
      <source src={HeroOgg} type="video/ogg" />
    </VideoWrapper>
  )
}
export default Video
