import styled from 'styled-components'

const Input = styled.input.attrs({
  maxLength: 5,
  pattern: '[0-9]*',
  placeholder: 'ZIP CODE',
  type: 'tel',
})`
  align-items: center;
  border: none;
  display: flex;
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 0.9rem;
  font-weight: 300;
  height: 3rem;
  justify-content: center;
  line-height: 0.9rem;
  padding: 0;
  text-align: center;
  flex: 1;
`

export default Input
