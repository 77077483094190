import React from 'react'
import styled from 'styled-components'

import {ReactComponent as Gas} from 'SVG/gaspump.svg'

const Wrapper = styled.figure`
  align-items: center;
  display: flex;
  height: 3.7rem;
  justify-content: center;
  margin: 0;
  width: 3.7rem;
  margin-right: 0.7rem;
  svg {
    height: 100%;
  }
`
const GasPump = () => {
  return (
    <Wrapper>
      <Gas />
    </Wrapper>
  )
}

export default GasPump