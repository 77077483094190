import React from 'react'
import Wrapper from './Wrapper'
const Footer = () => {
  return (
    <Wrapper>
      <p>
        &sup1;Fuel Rewards<sup>®</sup> members: To earn the one-time 40¢/gal
        reward, you must: (1) be a Fuel Rewards<sup>®</sup> member; and (2) join AAA by
        August 31, 2020. Allow 6 – 8 weeks for this offer to be credited to your
        Fuel Rewards account. Void where prohibited, only available in U.S. Fuel
        Rewards<sup>®</sup> savings are limited to 20 gallons of fuel per purchase per
        vehicle or fraud limits placed by Shell and/or limits placed on your
        payment card by your financial institution, each of which may be lower.
        Dispenser may require a price of up to $0.109/gallon. Once you begin to
        dispense fuel using your Fuel Rewards<sup>®</sup> savings, you must dispense to the
        20-gallon limit or you forfeit any remaining gallons. If you have a
        rewards balance greater than the current per-gallon price of fuel, the
        price will roll down to the maximum capability of the fuel equipment,
        and your remaining rewards balance will be saved for a future fuel
        purchase. For purchases of $75 or more, please go inside to pay.
        Unbranded diesel and alternative fuels may not be eligible. The Fuel
        Rewards<sup>®</sup> program is owned and operated by Excentus Corporation. Offers
        may be modified or discontinued at any time without notice. Not valid
        where prohibited by law. Please see www.fuelrewards.com for complete
        Fuel Rewards<sup>®</sup> program details and Terms and Conditions. Other
        restrictions may apply. All trademarks are property of their respective
        owners. &sup2;Restrictions apply. For full program terms and conditions, see
        fuelrewards.com/gold.
      </p>
    </Wrapper>
  )
}

export default Footer
