import React from 'react'
import styled from 'styled-components'
import Backdrop from './Backdrop'
import Pane from './Pane'

const ModalContainer = styled.section`
  position: relative;
  z-index: +1;
  &.c-enter {
    opacity: 0;
    transition: opacity ${(props) => props.theme.animation.modalTimer}ms;
  }

  &.c-enter.c-enter-active {
    opacity: 1;
  }

  &.c-leave {
    opacity: 1;
    transition: opacity ${(props) => props.theme.animation.modalTimer}ms;
  }

  &.c-leave.c-leave-active {
    opacity: 0;
  }
`
const Modal = (props) => {
  return (
    <ModalContainer>
      <Backdrop onClick={props.clicked} />
      <Pane
        clickClose={props.clicked}
        clubcode={props.matchClubCode}
        onClickButton={props.onClickButton}
        queryZip={props.queryZip}
        resultsMessage={props.resultsMessage}
        setZip={props.setZip}
        state={props.matchState}
        url={props.url}
        zip={props.zip}
      />
    </ModalContainer>
  )
}

export default Modal
