import React from 'react'
import Button from 'Blocks/Button'
import Input from './Input'
import { FormWrapper } from './Wrapper'
const ZipForm = (props) => {
  return (
    <FormWrapper hero={props.hero}>
      <Input onChange={props.setZip} pattern="[0-9]*" value={props.zip} />
      <Button
        type="redButton"
        label="Enter zip for special AAA offer"
        clickHandler={props.isInModal ? props.onClickButton : props.handleHeroButtonClick}
      />
    </FormWrapper>
  )
}

export default ZipForm
