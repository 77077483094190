import React from 'react'
import PrimaryText from './PrimaryText'
import SecondaryText from './SecondaryText'
import Wrapper from './Wrapper'
const OverlayBanner = (props) => {
  return (
    <Wrapper>
      {props.primaryText && <PrimaryText>{props.primaryText}</PrimaryText>}
      {props.secondaryText && <SecondaryText>{props.secondaryText}</SecondaryText>}
    </Wrapper>
  )
}

export default OverlayBanner
