import React from 'react'
import { ReactComponent as ChangeFlatTire } from 'SVG/carousel-icon-changeflattire.svg'
import { ReactComponent as DeliverGas } from 'SVG/carousel-icon-delivergas.svg'
import { ReactComponent as JumpStart } from 'SVG/carousel-icon-jumpstart.svg'
import { ReactComponent as MechanicalFirstAid } from 'SVG/carousel-icon-mechanicalfirstaid.svg'
import { ReactComponent as NewBattery } from 'SVG/carousel-icon-newbattery.svg'
import { ReactComponent as OpenLockedCar } from 'SVG/carousel-icon-openlockedcar.svg'
import { ReactComponent as TowToSafety } from 'SVG/carousel-icon-towtosafety.svg'

import styled from 'styled-components'

const Wrapper = styled.figure`
  margin: 0;
  ${(props) =>
    props.forAccordion &&
    `
    margin-right: 1rem;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);

  `}
  svg {
    height: 100%;
    width: 100%;
    max-height: 3.8rem;
    max-width: 6.65rem;
    ${(props) =>
      props.forAccordion &&
      `
      max-height: 2rem;
      max-width: 4rem;
    `}
  }
`

const Images = {
  ChangeFlatTire,
  DeliverGas,
  JumpStart,
  MechanicalFirstAid,
  NewBattery,
  OpenLockedCar,
  TowToSafety,
}

const CarouselButtonIcon = (props) => {
  const Component = Images[props.icon]
  return (
    <Wrapper forAccordion={props.forAccordion}>
      <Component />
    </Wrapper>
  )
}

export default CarouselButtonIcon
