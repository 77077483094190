import React from 'react'
import { GasPump } from 'Blocks/Image'
import { LargeRed } from './TextEffects'
import { TopLeft as Wrapper } from './Wrapper'
const TopLeft = (props) => {
  return (
    <Wrapper>
      <GasPump />
      <div>
        <p>
          <LargeRed>
            Save 40¢ per gallon
          </LargeRed> on a one&#8209;time fill up to 20 gallons&sup1; and...
        </p>
      </div>
    </Wrapper>
  )
}

export default TopLeft
