import styled from 'styled-components'
const Gold = styled.span`
  color: ${(props) => props.theme.colors.gold};
`
const Pink = styled.span`
  background-color: ${(props) => props.theme.colors.pink};
`
const LargeRed = styled.span`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.9rem;
  font-weight: 700;
`
export { Gold, LargeRed, Pink }
