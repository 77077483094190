import styled from 'styled-components'

const Bottom = styled.div`
  background-color: ${(props) => props.theme.colors.lightestGrey};
  display: flex;
  flex-direction: row;
  height: 12.5rem;
  padding: 1.4rem 2.9rem 2.4rem 4rem;
  @media screen and (max-width: 600px) {
    padding: 1.4rem 2.9rem 2.4rem;
    height: auto;
  }
`
const BottomLeft = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  @media screen and (max-width: 800px) {
    flex: 0;
    margin-right: 2.4rem;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
`
const BottomRight = styled.div`
  min-width: 39rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    min-width: auto;
  }
`
const Outside = styled.div`
  background-color: white;
  bottom: -6.6rem;
  display: flex;
  flex-direction: column;
  /* height: 27.2rem; */
  position: absolute;
  width: 57.5rem;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`
const Top = styled.div`
  display: flex;
  flex-direction: row;
  /* height: 14.8rem; */
  justify-content: space-between;
  padding: 2.2rem 2.9rem 2.9rem;
  font-size: 1.35rem;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding-top: 1rem;
  }
  @media screen and (max-width: 600px) {
    padding-top: 2.2rem;
    height: auto;
  }
  @media screen and (max-width: 550px) {
    padding-bottom: 1rem;
  }
`
const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 18.5rem;
  & p {
    margin: 0;
  }
  @media screen and (max-width: 800px) and (min-width: 601px) {
    max-width: none;
    margin-bottom: 1rem;

    align-items: flex-end;
    & figure {
      transform: translateY(1.5rem);
    }
  }
  @media screen and (max-width: 600px) {
    max-width: none;
    margin-bottom: 1rem;
    & figure {
      display: none;
    }
  }
`
const TopRight = styled.div`
  flex: 1;
  max-width: 31rem;
  sup {
    font-size: .5em;
  }
  & h4 {
    margin: 0;
  }
  & p {
    margin-top: 0;
  }
  @media screen and (max-width: 800px) and (min-width: 601px) {
    max-width: none;
    padding-left: 4.4rem;
  }
  @media screen and (max-width: 600px) {
    max-width: none;
    & p {
      margin-bottom: 0;
    }
  }
`

export { Bottom, BottomLeft, BottomRight, Outside, Top, TopLeft, TopRight }
