import React from 'react'
import { BottomRight as Wrapper } from './Wrapper'
import ZipForm from 'Blocks/ZipForm'
import { Bonus } from 'Blocks/Image'
const BottomRight = (props) => {
  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Bonus />
        <p style={{ margin: '0 0 0 1.5rem', fontSize: '1.5rem' }}>
          <b>There’s EVEN MORE for you from AAA!</b> Enter your zip code here to
          find out...
        </p>
      </div>
      <ZipForm
        handleHeroButtonClick={props.handleHeroButtonClick}
        hero
        setZip={props.setZip}
        toggleModal={props.toggleModal}
        zip={props.zip}
      />
    </Wrapper>
  )
}
export default BottomRight
