import React from 'react'
import styled from 'styled-components'

import { ReactComponent as AAAOrbit } from 'SVG/logo-aaaorbit.svg'
import { ReactComponent as FuelRewards } from 'SVG/logo-fuelrewards.svg'

const Wrapper = styled.i`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 5.2rem;
  svg {
    height: 100%;
  }
  @media screen and (max-width: 600px) {
    height: 3.5rem;
  }
  @media screen and (max-width: 450px) {
    height: 2.2rem;
  }
`

const logoSVGs = {
  aaaOrbit: <AAAOrbit />,
  fuelRewards: <FuelRewards />,
}
const HeaderLogo = (props) => {
  const svgContent = logoSVGs[props.name]
  return <Wrapper>{svgContent}</Wrapper>
}

export default HeaderLogo
