import styled from 'styled-components'
const CloseButton = styled.figure.attrs({
  alt: 'Close'
})`
  border-radius: 50px;
  border: 2px solid black;
  height: 24px;
  margin: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 24px;
  cursor: pointer;
  &:before,
  &:after {
    background-color: #333;
    border-radius: 1px;
    content: ' ';
    height: 12px;
    left: 9px;
    position: absolute;
    top: 4px;
    width: 2px;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

export default CloseButton
