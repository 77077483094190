import React from 'react'
import styled from 'styled-components'

import ChangeFlatTire from 'JPG/carousel-slide-changeflattire.jpg'
import DeliverGas from 'JPG/carousel-slide-delivergas.jpg'
import JumpStart from 'JPG/carousel-slide-jumpstart.jpg'
import MechanicalFirstAid from 'JPG/carousel-slide-mechanicalfirstaid.jpg'
import NewBattery from 'PNG/carousel-slide-newbattery.png'
import OpenLockedCar from 'JPG/carousel-slide-openlockedcar.jpg'
import TowToSafety from 'JPG/carousel-slide-towtosafety.jpg'

const images = {
  ChangeFlatTire,
  DeliverGas,
  JumpStart,
  MechanicalFirstAid,
  NewBattery,
  OpenLockedCar,
  TowToSafety,
}

const Image = styled.img`
  max-width: 30rem;
  @media screen and (max-width: 800px) {
    max-height: 20rem;
  }
  @media screen and (max-width: 450px) {
    max-width: 100%;
  }
`

const CarouselSlideImage = props => {
  const source = images[props.source]
  return <Image src={source} />
}
export default CarouselSlideImage