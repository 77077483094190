import React from 'react'
import { TopRight as Wrapper } from './Wrapper'
import { Gold, LargeRed } from './TextEffects'

const TopRight = (props) => {
  return (
    <Wrapper>
      <h4>
        <LargeRed>
          Earn Instant <Gold>Gold Status</Gold> in the Fuel Rewards<sup>®</sup>
          program&sup2;
        </LargeRed>
      </h4>
      <p>
        free of any purchase requirement! Your Fuel Rewards<sup>®</sup> Gold Status saves
        you at least 5¢/gal all the time, for as long as you stay a AAA Member!
      </p>
    </Wrapper>
  )
}
export default TopRight
