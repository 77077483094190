import styled from 'styled-components'

const Wrapper = styled.aside`
  align-items: center;
  background-color: ${(props) => props.theme.colors.transparentBlack};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3.6rem;
  padding-top: 2rem;
  position: absolute;
  width: 100%;
  @media screen and (max-width: 650px) {
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media screen and (max-width: 370px) {
    padding-bottom: 1rem;
  }
  sup {
    font-size: .5em;
  }
`
export default Wrapper
