import styled from 'styled-components'

const SecondaryText = styled.h4`
  color: white;
  font-size: 2.1rem;
  margin: 0;
  @media screen and (max-width: 650px) {
    font-size: 1.2rem;
    text-align: center;
  }
  @media screen and (max-width: 370px) {
    display: none;
  }
`
export default SecondaryText