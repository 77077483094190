import styled from 'styled-components'
const TopSpacer = styled.div`
  height: 78px;
  @media screen and (max-width: 600px) {
    height: 61px;
  }
  @media screen and (max-width: 450px) {
    height: 56px;
  }
`
export {TopSpacer}
