import styled from 'styled-components'
const FormWrapper = styled.div`
  background-color: white;
  box-shadow: ${(props) => props.theme.shadows.buttonShadow};
  display: flex;
  height: 3.2rem;
  justify-content: space-between;
  padding: 1px;
  width: 100%;
  @media screen and (max-width: 440px) {
    flex-direction: column;
    height: auto;
    & input {
      line-height: 3rem;
    }
  }
  ${(props) =>
    props.hero &&
    `
    @media screen and (max-width: 600px) {
      margin-top: 1.4rem;
    }
  `}
`
export { FormWrapper }
