/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { CSSTransitionGroup } from 'react-transition-group'

import { TopSpacer } from 'Blocks/Spacer'
import Footer from 'Blocks/Footer'
import Header from 'Blocks/Header'
import Hero from 'Blocks/Hero'
import MaxWidthContainer from 'Blocks/MaxWidthContainer'
import Modal from 'Blocks/Modal'

/* 
These are the sections of the site that are commented out inside the App component. I commented them out here so that 
the linter doesn't freak out.  
*/
// import Accordion from 'Blocks/Accordion'
// import ButtonCarousel from 'Blocks/ButtonCarousel'
// import CardGrid from 'Blocks/CardGrid'
// import Heading from 'Blocks/Heading'
// import InsuranceSupplement from 'Blocks/InsuranceSupplement'
// import SubHero from 'Blocks/SubHero'

import { admrTheme } from 'Theme/admrClient'
import GlobalStyle from 'Theme/globalStyle'

// Variables for google sheets!
const API_KEY = process.env.REACT_APP_API_KEY
const SHEET_ID = process.env.REACT_APP_SHEET_ID
const API = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values:batchGet?majorDimension=ROWS&ranges=Sheet1&key=${API_KEY}`

function App () {
  // Checking, matching hooks
  const [doesMatch, setDoesMatch] = useState(false) /* Does the entered zip code match the zip code from the sheet? */
  const [hasCheckedZip, setHasCheckedZip] = useState(false) /* Have we checked for a match yet? */
  
  // These hooks hold data for the matched row
  const [matchClubCode, setMatchClubCode] = useState('')
  const [matchState, setMatchState] = useState('')
  const [matchURL, setMatchURL] = useState('')
  
  // These are used upon fetch
  const [hasFetchedSheetData, setHasFetchedSheetData] = useState(false)
  const [sheetData, setSheetData] = useState('')

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [modalMessageSlug, setModalMessageSlug] = useState('unChecked')
  
  // Query zip is used in the modal error message for your previous query.  It is only used for messaging.
  const [queryZip, setQueryZip] = useState('')

  const [zip, setZip] = useState('')
  
  // This effect fires if the sheet data hasn't been fetched
  useEffect(() => {
    if (hasFetchedSheetData === false) {
      fetch(API)
        .then((response) => response.json())
        .then((data) => {
          let batchRowValues = data.valueRanges[0].values
          const rows = []
          for (let i = 1; i < batchRowValues.length; i++) {
            let rowObject = {}
            for (let j = 0; j < batchRowValues[i].length; j++) {
              rowObject[batchRowValues[0][j]] = batchRowValues[i][j]
            }
            rows.push(rowObject)
          }
          setSheetData(rows)
          if (sheetData) {
            console.log('Sheet Data: ')
            console.log(sheetData)
          }
          setHasFetchedSheetData(true)
        })
    }
  })
  
  // These effects relate to when a check happens, and if a match happens.
  useEffect(() => {
    // This is if we have a match (checks if zip has been checked, entered too)
    doesMatch &&
      zip &&
      hasCheckedZip &&
      console.log(`
    Matched! 
    Zip: ${zip}
    Club code: ${matchClubCode}
    State: ${matchState}
    URL: ${matchURL}
    `)
    // Sets the modal slug to matched if we have a match
    doesMatch && setModalMessageSlug('matched')
    // Sets the redirect url if we have a match
    doesMatch && redirect(matchURL)
    // Sets the slug if we DO NOT have a match
    hasCheckedZip && !doesMatch && setModalMessageSlug('unMatched')
  }, [doesMatch, hasCheckedZip, matchClubCode, matchState, matchURL, zip])

  // This handles a change in the zip code in the form, any formatting and sanitization
  const handleSetZip = (e) => {
    setZip(e.target.value.replace(/\D/, ''))
  }
  
  // This is the redirect function
  const redirect = (url) => {
    setTimeout(() => {
      window.location.href = url
    }, 3000)
  }
  
  // This toggles the boolean modalIsOpen
  const toggleModal = () => {
    let isModalOpen = modalIsOpen
    setModalIsOpen(!isModalOpen)
  }

  // This sets the related state, url, and code data when we have a match
  const setMatchData = (row) => {
    setMatchClubCode(row.clubcode)
    setMatchState(row.state)
    setMatchURL(row.url)
    setDoesMatch(true)
  }

  // This rolls through the sheet data, and when if finds a match, it sets the corresponding state data
  const checkForZip = () => {
    console.log('Checking for zip code match...')
    setQueryZip(zip)
    setHasCheckedZip(true)
    sheetData.forEach((row) => {
      row.zip === zip && setMatchData(row)
    })
  }

  // This handles clicking the form button in the hero (vs in the modal)
  const handleHeroButtonClick = () => {
      setModalIsOpen(true)
      zip !== '' && checkForZip()
  }

  // This resets everything except the scraped sheet data
  const resetAll = () => {
    setHasCheckedZip(false)
    setModalIsOpen(false)
    setModalMessageSlug('unChecked')
    setQueryZip('')
    setZip('')
  }

  let modal = modalIsOpen ? (
    <Modal
      clicked={() => resetAll()}
      clubcode={matchClubCode}
      onClickButton={() => checkForZip()}
      queryZip={queryZip}
      resultsMessage={modalMessageSlug}
      setZip={(e) => handleSetZip(e)}
      show={modalIsOpen}
      state={matchState}
      url={matchURL}
      zip={zip}
    />
  ) : (
    ''
  )
  return (
    <ThemeProvider theme={admrTheme}>
      <GlobalStyle />
      <CSSTransitionGroup
        component={React.Fragment}
        transitionName="c"
        transitionEnterTimeout={admrTheme.animation.modalTimer}
        transitionLeaveTimeout={admrTheme.animation.modalTimer}
      >
        {modal}
      </CSSTransitionGroup>
      <Header toggleModal={() => toggleModal()} />
      <MaxWidthContainer>
        <TopSpacer />
        <Hero
          handleHeroButtonClick={() => handleHeroButtonClick()}
          toggleModal={() => toggleModal()}
          setZip={(e) => handleSetZip(e)}
          zip={zip}
        />
        {/*<Heading forSubHero>
          The many reasons why AAA just makes sense...
        </Heading>
        <SubHero />
        <Accordion />
        <ButtonCarousel />
        <InsuranceSupplement />
        <Heading>
          Today, AAA provides Roadside Assistance to over 59,000,000 Members and
          … so much more!
        </Heading>
        <CardGrid />*/}
        <Footer />
      </MaxWidthContainer>
    </ThemeProvider>
  )
}

export default App

/* eslint-disable no-restricted-globals */
