import React from 'react'
import { HeaderLogo } from 'Blocks/Image'
import { Top, Bottom, BottomLeft, Outside } from './Wrapper'
import BottomRight from './BottomRight'
import TopLeft from './TopLeft.js'
import TopRight from './TopRight.js'
const HeroCTA = (props) => {
  return (
    <Outside>
      <Top>
        <TopLeft />
        <TopRight />
      </Top>
      <Bottom>
        <BottomLeft>
          <HeaderLogo name="aaaOrbit" />
        </BottomLeft>
        <BottomRight
          handleHeroButtonClick={props.handleHeroButtonClick}
          setZip={props.setZip}
          toggleModal={props.toggleModal}
          zip={props.zip}
        />
      </Bottom>
    </Outside>
  )
}

export default HeroCTA
