import styled from 'styled-components'
const Wrapper = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2.3rem;
  font-size: .7rem;
  p {
    /* max-width: 80rem; */
  }
  sup {
    font-size: .5em;
  }
  @media screen and (min-width: 1440px) {
    padding: 0;
  }
`
export default Wrapper