import styled from 'styled-components'

const PrimaryText = styled.h3`
  color: white;
  font-size: 3.7rem;
  margin-bottom: 1.6rem;
  margin-top: 0;
  @media screen and (max-width: 650px) {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
`
export default PrimaryText
