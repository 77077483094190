import React from 'react'
import HeroCTA from 'Blocks/HeroCTA'
import OverlayBanner from 'Blocks/OverlayBanner'
import Video from './Video'
import Wrapper from './Wrapper'

const Hero = props => {
  return (
    <Wrapper>
      <Video />
      <OverlayBanner
        primaryText="Here’s how to save 40¢/gal at Shell!"
        secondaryText={["Because you’re a Fuel Rewards",<sup>®</sup>, " member, simply join AAA to..."]}
      />
      <HeroCTA
        handleHeroButtonClick={props.handleHeroButtonClick}
        setZip={props.setZip}
        toggleModal={props.toggleModal}
        zip={props.zip}
      />
    </Wrapper>
  )
}

export default Hero
